<template>
    <v-form>
        <v-alert
          v-if="!_.isNil(summaryError)"
          dense
          type="error"
        >
            {{ summaryError }}
        </v-alert>

        <v-text-field 
            v-model="$v.form.name.$model"
            :error-messages="getErrors('form.name')"
            label="Название" 
            name="name" 
            type="text" 
            placeholder=" "
            :color="$const.color.primary" 
        ></v-text-field>

        <v-select
            v-model="$v.form.category.$model"
            :error-messages="getErrors('form.category')"
            required
            :disabled="fetchingTasks"
            :items="categories"
            label="Категория"
            @change="resetSelectedTasks"
        ></v-select>

        <v-select
            v-if="form.category === 'control'"
            v-model="$v.form.collection.$model"
            :error-messages="getErrors('form.collection')"
            required
            :disabled="fetchingTasks"
            :items="collections"
            label="Коллекция"
            name="collection"
            hint="Используйте, чтобы объеденить задания/работы в группу. Например, контрольная `первой четверти` = коллекция 1"
            persistent-hint
            @change="resetSelectedTasks"
        ></v-select>

        <v-text-field 
            v-model="$v.form.year.$model"
            :error-messages="getErrors('form.year')"
            label="Год" 
            name="year"
            type="number"
            :disabled="fetchingTasks"
            :color="$const.color.primary" 
            @change="resetSelectedTasks"
        ></v-text-field>

        <v-select
            v-model="$v.form.subject.$model"
            :error-messages="getErrors('form.subject')"
            required
            :items="subjects"
            :disabled="fetchingTasks"
            label="Предмет"
            @change="resetSelectedTasks"
        ></v-select>

        <v-select
            v-model.number="$v.form.grade.$model"
            :error-messages="getErrors('form.grade')"
            required
            :items="grades"
            :disabled="fetchingTasks"
            label="Класс"
            @change="resetSelectedTasks"
        ></v-select>

        <v-text-field 
            v-model="$v.form.option.$model"
            :error-messages="getErrors('form.option')"
            label="Вариант" 
            name="option"
            type="number"
            :disabled="fetchingTasks"
            :color="$const.color.primary"
            @change="resetSelectedTasks"
        ></v-text-field>

        <v-text-field 
            v-model="$v.form.part_divider_task_number.$model"
            :error-messages="getErrors('form.part_divider_task_number')"
            label="Номер задания с которого начинается 2я часть работы"
            hint="Необходимо для разделения работы на 2 части. Минимально допустимое значение - 2. Если часть всегда должна быть 1, не вводите ни каких значений."
            name="part_divider_task_number"
            type="number"
            clearable
            :color="$const.color.primary"
             class="mb-2"
        ></v-text-field>

        <v-dialog
            v-if="form.category === 'control'"
            v-model="dialogs.access_started_at"
            persistent
            width="400"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="$v.form.access_started_at.$model"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :error-messages="getErrors('form.access_started_at')"
                    label="Дата и время начала доступа к работе"
                    placeholder="Введите дату"
                    prepend-inner-icon="mdi-calendar-clock"
                    class="mb-5"
                />
            </template>
            <v-date-time-picker
                v-model="form.access_started_at"
                :date-picker-props="{
                    'width': 400,
                    'scrollable': true,
                    'no-title': true,
                    'first-day-of-week': 1
                }"
            >
                <div class="d-flex flex-nowrap justify-center align-center">
                    <v-btn
                        text
                        color="primary"
                        @click="dialogs.access_started_at = false"
                    >
                        Выбрать
                    </v-btn>
                </div>
            </v-date-time-picker>
        </v-dialog>

        <v-dialog
            v-if="form.category === 'control'"
            v-model="dialogs.access_ended_at"
            persistent
            width="400"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="$v.form.access_ended_at.$model"
                    :error-messages="getErrors('form.access_ended_at')"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    label="Дата и время окончания доступа к работе"
                    placeholder="Введите дату"
                    prepend-inner-icon="mdi-calendar-clock"
                    class="mb-5"
                />
            </template>
            <v-date-time-picker
                v-model="form.access_ended_at"
                :date-picker-props="{
                    'width': 400,
                    'scrollable': true,
                    'no-title': true,
                    'first-day-of-week': 1
                }"
            >
                <div class="d-flex flex-nowrap justify-center align-center">
                    <v-btn
                        text
                        color="primary"
                        @click="dialogs.access_ended_at = false"
                    >
                        Выбрать
                    </v-btn>
                </div>
            </v-date-time-picker>
        </v-dialog>

        <template v-if="model">
            <v-label>Источник работы (для печати)</v-label>
            <media-library
                v-model="$v.form.source.$model"
                :path="`${model.year}/${transliterate(model.subject.toLowerCase())}/${model.grade}`"
                type="pdf"
                class="mb-5 mt-1"
            />
        </template>
        <v-alert v-else type="warning" class="mb-5">
            Возможность использования медиа-библиотеки заблокирована для несохранённой формы.
            Сохраните форму и обновите страницу.
        </v-alert>

        <!-- <v-label>Инструменты</v-label>
        <v-card class="d-flex align-center pa-3 mb-5 mt-1">
            <template v-if="form.tools">
                <v-checkbox
                    v-model="form.tools.triangle"
                    label="Треугольник"
                    hide-details
                    class="mr-5 my-0"
                />
                <v-checkbox
                    :input-value="form.tools.protractor"
                    label="Транспортир"
                    hide-details
                    class="mr-5 my-0"
                    @change="onCheckboxChanged('form.tools.protractor', $event)"
                />
            </template>
        </v-card> -->
        <v-data-table
            v-model="selectedTasks"
            dense
            disable-sort
            disable-pagination
            disable-filtering
            hide-default-footer 
            :calculate-widths="true"
            :headers="[
                { text: 'Номер задания', value: 'task', width: 1 },
                { text: 'Вариант', value: 'option', width: 1 },
                { text: 'Коллекция', value: 'collection', width: 1 },
                // { text: '', value: 'btn_toggle', width: 1 }
            ]"
            :items="tasks"
            show-select
            checkbox-color="#E7EEF4"
            item-key="id"
            height="25vh"
            class="elevation-0"
            >
        </v-data-table>

        <template v-if="lockingInteractivesAmongSelectedTasks.length">
            <v-divider class="my-4" />

            <v-label>Настройки блокировки заданий</v-label>

            <v-alert
                v-if="!form.id"
                dense
                type="error"
            >
                Эта область настроек будет доступна после сохранения работы
            </v-alert>
            
            <v-card v-else class="d-flex flex-column pa-3 my-4">

                <div
                    v-for="item in lockingInteractivesAmongSelectedTasks"
                    :key="item.task.id"
                    class="d-flex flex-column"
                >
                    <span class="mb-2">Задание №{{ item.task.number }}</span>
                    <div
                        v-for="type in item.inputTypes"
                        :key="type"
                        class="d-flex align-center w-100 pl-5"
                    >
                        <span style="width: 50%">Интерактив <strong>{{ $const.editorBlockTypes[type] }}</strong></span>
                        <div style="width: 50%">
                            <v-autocomplete
                                :key="selectedTasks.length"
                                v-model="lockedTasksToKey[`${type}:${item.task.id}`]"
                                :items="selectedTasksList"
                                outlined
                                hide-details
                                dense
                                small-chips
                                multiple
                                label="Блокируемые задания"
                            ></v-autocomplete>
                        </div>
                    </div>
                </div>
            </v-card>
            
        </template>
        
        <v-divider class="my-4" />

        <action-buttons 
            :waiting-save-and-back="waiting.save.back"
            no-save-and-update
            @back="back"
            @save="save"
        />
    </v-form>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { errorMixin, saveMixin } from '@/mixins/formMixin'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

import VDateTimePicker from '@/components/vuetify/VDateTimePicker.vue'
import ActionButtons from '@/components/crud/ActionButtons.vue'
import MediaLibrary from '@/components/inputs/media-library'
import StringHelper from '@/plugins/string'

const getDefaultTools = () => ({
    triangle: null,
    protractor: null
})
const blockingInteractives = ['audio-dictation']
const dateTimeFormat = 'YYYY-MM-DD HH:mm'

export default {
    name: 'JobForm',
    components: { VDateTimePicker, ActionButtons, MediaLibrary },
    props: {
        model: {
            type: Object
        }
    },
    mixins: [errorMixin, saveMixin, validationMixin],
    data () {
        return {
            storeModule: 'job',
            tasks: [],
            selectedTasks: [],
            fetchedLockedTasks: [],
            lockedTasksToKey: {},
            categories: [ { text: 'ВПР', value: 'test' }, { text: 'Контрольная работа', value: 'control' } ],
            fetchingTasks: false,
            dialogs: {
                access_started_at: false,
                access_ended_at: false
            },
            form: {
                name: '',
                subject: '',
                source: null,
                year: null,
                category: 'test',
                collection: 1,
                grade: null,
                option: 1,
                task_id: null,
                part_divider_task_number: null,
                tools: getDefaultTools(),
                access_started_at: parseInt(Date.now() / 1000),
                access_ended_at: parseInt(Date.now() / 1000)
            }
        };
    },
    computed: {
        ...mapState('app', ['currentYear']),
        ...mapState('app', ['subjects']),
        ...mapGetters('job', ['grades']),
        selectedTasksList () {
            return this.selectedTasks.map(task => ({
                text: `Номер ${task.task}`,
                value: task.id
            }))
        },
        lockingInteractivesAmongSelectedTasks () {
            return this.selectedTasks.map(task => {
                        const data = typeof task.data === 'string' ? JSON.parse(task.data) : task.data;
                        const inputTypes = data.subtasks.map(subtask => subtask.inputs.map(input => input.type))?.flat()
                        return {
                                task: this.selectedTasks.find(selected => selected.id === task.id),
                                inputTypes: Array.from(new Set( inputTypes
                                                                    .filter(type => blockingInteractives.includes(type))
                                                                )
                                                    )
                            }
                    })
                    .filter(task => task.inputTypes.length)
        },
        transliterate () { return StringHelper.transliterate },
        collections () {
            return (new Array(10)).fill(null).map((item, index) => index + 1)
        }
    },
    validations() {
        return {
            form: {
                name: { required },
                subject: { required },
                grade: { required },
                task_id: { required },
                option: { required },
                year: { required },
                source: {},
                tools: {},
                category: { required },
                access_started_at: {},
                access_ended_at: {},
                collection: { required },
                part_divider_task_number: {}
            }
        }
    },
    watch: {
        'form.subject'() {
            this.fetchTasks();
        },
        'form.grade'() {
            this.fetchTasks();
        },
        'form.year'() {
            this.fetchTasks();
        },
        'form.option'() {
            this.fetchTasks();
        },
        'form.category'() {
            if (this.form.category === 'test')
                this.form.collection = 1
            this.fetchLockedTasks()
            this.fetchTasks()
        },
        'form.collection'() {
            this.fetchTasks();
        },
        tasks: {
            handler () {
                if (!(this.isUpdate && this.form.task_id?.length)) { return; }
                this.selectedTasks = this.tasks.filter(task => this.form.task_id?.includes(task.id));
            },
            deep: true
        },
        selectedTasks(val) {
            this.form.task_id = _.map(val, 'id') || null;
        }
    },
    created () {

        if (!this.form.part_divider_task_number)
            this.form.part_divider_task_number = null

        this.fetchLockedTasks();

        if (this.form.access_started_at)
                this.form.access_started_at = this.$moment.unix(this.form.access_started_at).format(dateTimeFormat);

        if (this.form.access_ended_at)
            this.form.access_ended_at = this.$moment.unix(this.form.access_ended_at).format(dateTimeFormat);

        if (this.isUpdate) {

            if (this.form.grade)
                this.form.grade = parseInt(this.form.grade);

            if (typeof this.form.tools === 'string')
                this.form.tools = JSON.parse(this.form.tools);

            if (!this.form.tools)
                this.form.tools = getDefaultTools();

            if (this.form.tasks.length)
                this.form.task_id = [...this.form.tasks];

            this.form.task_id = _.uniq(this.form.task_id || []);
        } else {
            this.form.year = this.currentYear
        }
    },
    methods: {
        resetSelectedTasks () {
            this.selectedTasks = []
        },
        onCheckboxChanged (field, value) {
            _.set(this, field, value ? true : null)
        },
        prepareForm (form) {
            const preparedForm = {
                                    ...form,
                                    task_id: _.uniq(form.task_id), // remove duplicates
                                    tools: JSON.stringify(form.tools)
                                }
            if (form.category === 'control') {
                preparedForm.access_started_at = this.$moment(form.access_started_at, dateTimeFormat).unix()
                preparedForm.access_ended_at = this.$moment(form.access_ended_at, dateTimeFormat).unix()
            }
            if (this.isCreate) {
                preparedForm.job_category = form.category
                preparedForm.category = 0
            }
            return preparedForm
        },
        async beforeSave () {

            if (this.form.category === 'test') {
                this.form.access_started_at = null
                this.form.access_ended_at = null
            }
            
            if (!this.form.id) { return; }

            const promises = [];
            const payloads = [];

            Object.keys(this.lockedTasksToKey).forEach(key => {
                
                this.lockedTasksToKey[key].forEach(taskId => {
                    
                    payloads.push({
                        job_id: this.form.id,
                        task_id: taskId,
                        unlock_key: key
                    });
                });
            });
            const itemsToDelete = this.fetchedLockedTasks.filter(fetchedItem => !payloads.find(payload => fetchedItem.task_id === payload.task_id && fetchedItem.unlock_key));
            
            itemsToDelete.forEach(item => {
                
                promises.push( this.$store.dispatch(`locked_tasks_of_job/delete`, item) );
            });

            payloads.forEach(item => {
                
                promises.push( this.$store.dispatch(`locked_tasks_of_job/upsert`, item) );
            })

            await Promise.all(promises)
        },
        onValidationFailed () {
            window.scrollTo(0, 0)
        },
        async fetchLockedTasks () {
            if (!this.form.id) { return; }
            const { data } = await this.$store.dispatch(`locked_tasks_of_job/list`, {
                pagination: 0,
                fields: 'job_id,task_id,unlock_key',
                filter: { job_id: this.form.id },
                sort: 'job_id'
            });
            this.fetchedLockedTasks = data?.items || [];

            this.fetchedLockedTasks.map(item => {
                if (!Array.isArray(this.lockedTasksToKey[item.unlock_key])) {
                    this.lockedTasksToKey[item.unlock_key] = [];
                }
                this.lockedTasksToKey[item.unlock_key].push(item.task_id);
            })
        },
        async fetchTasks() {
            if (this.fetchingTasks) { return }

            if(!this.form.year || (new String(this.form.year)).length < 4 || !this.form.subject || _.isNil(this.form.grade)) {
                this.tasks = []
                return
            }
            this.fetchingTasks = true

            try {

                const { data } = await this.$store.dispatch(`task/list`, {
                    pagination: 0,
                    filter: {
                        year: this.form.year,
                        subject: this.form.subject,
                        grade: this.form.grade || 0,
                        category: this.form.category,
                        collection: this.form.collection,
                        option: this.form.option || undefined
                    },
                    fields: 'task,option,collection,data'
                });
                
                this.tasks = _.map(_.get(data, 'items', []), (o,k) => {
                    o.number = k + 1;
                    return o;
                })
                .sort((a, b) => {
                    const aValue = this.form.task_id?.includes(a.id) ? 1 : 0;
                    const bValue = this.form.task_id?.includes(b.id) ? 1 : 0;
                    return bValue - aValue;
                });
            } catch (e) {
                console.error(e);
            } finally {
                this.fetchingTasks = false;
            }
        }
    }
}
</script>